import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Typography, Box, Container, InputBase } from '@mui/material';
import axios from 'axios';
import SimpleBottomNavigation from './SimpleBottomNavigation';
import Header from './Header';
import { styled } from '@mui/material/styles';
import api from './Interceptor';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  '& .MuiTablePagination-select': {
    marginRight: theme.spacing(2),
  },
  '& .MuiTablePagination-actions': {
    marginLeft: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '5px',
  fontSize: '14px',
  padding: '5px 10px',
  ...(variant === 'success' && {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  }),
  ...(variant === 'danger' && {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  }),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

function UserTransactions() {
    const [transactions, setTransactions] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // Number of items per page

    // Replace '312202' with the actual user registration ID
    const userRegistrationId = '312202'; // Example user registration ID

    useEffect(() => {
        // Fetch user transactions from the API endpoint
        api.get(`/UserTransactions/by-user/${userRegistrationId}`)
            .then(response => {
                setTransactions(response.data); // Set the fetched data
            })
            .catch(error => {
                console.error("There was an error fetching the data!", error);
            });
    }, [userRegistrationId]);

    // Filter data based on search input
    const filteredTransactions = transactions.filter(item =>
        item.id.toString().includes(searchInput) ||
        item.amount.toString().includes(searchInput)
    );

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page
    };

    return (
        <Container sx={{ backgroundColor: '#f4f4f9', minHeight: '100vh', paddingY: 4, marginTop: '10%' }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ color: "#0056b3", mb: 4 }}>
                User Transactions
            </Typography>

            <StyledInput
                placeholder="Search by Id or Amount"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>S. No.</StyledTableCell>
                            <StyledTableCell>Id</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Date of Request</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transaction, index) => (
                            <StyledTableRow key={transaction.id}>
                                <TableCell>{(page * rowsPerPage) + index + 1}</TableCell>
                                <TableCell>{transaction.id}</TableCell>
                                <TableCell>{transaction.amount}</TableCell>
                                <TableCell>{new Date(transaction.dateOfRequest).toLocaleString()}</TableCell>
                                <TableCell>{transaction.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <StyledPagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredTransactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            
            <SimpleBottomNavigation />
        </Container>
    );
}

export default UserTransactions;
