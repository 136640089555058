import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import SignalCellularAltTwoToneIcon from '@mui/icons-material/SignalCellularAltTwoTone';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';

function SimpleBottomNavigation() {
    const navigate = useNavigate(); // Initialize useNavigate
    const [value, setValue] = useState(0);

    const handleNavigation = (path) => {
        navigate(path); // Navigate to the specified path
    };

    return (
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0 }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => setValue(newValue)}
                sx={{ backgroundColor: '#f9f9f9', color: '#000' }} // Adjust color to ensure visibility
            >
                <BottomNavigationAction
                    label="Home"
                    icon={<HomeIcon />}
                    onClick={() => handleNavigation('/index')} // Home path
                />
                <BottomNavigationAction
                    label="Level"
                    icon={<SignalCellularAltTwoToneIcon />}
                    onClick={() => handleNavigation('/team')} // Team path
                />
                <BottomNavigationAction
                    label="Wallet"
                    icon={<AccountBalanceWalletIcon />}
                    onClick={() => handleNavigation('/wallet')} // Wallet path
                />
                <BottomNavigationAction
                    label="Profile"
                    icon={<AccountBoxRoundedIcon />}
                    onClick={() => handleNavigation('/profile')} // Profile path
                />
            </BottomNavigation>
        </Box>
    );
}

export default SimpleBottomNavigation;
