import React from 'react';

function ContactUs() {
  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}>Contact Us</h2>
        <p style={styles.description}>
          If you have any questions or inquiries, feel free to reach out to us at:
        </p>
        <p style={styles.email}>
          <strong>Email:</strong>
          <a href="mailto:contact@metaormoney.io" style={styles.link}>
         contact@metaormoney.io
          </a>
        </p>
        <p style={styles.footer}>We look forward to hearing from you!</p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    backgroundColor: '#f4f4f4',
  },
  card: {
    maxWidth: '600px',
    width: '100%',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: '15px',
    fontSize: '24px',
    color: '#333',
  },
  description: {
    marginBottom: '15px',
    color: '#555',
  },
  email: {
    marginBottom: '15px',
    color: '#333',
  },
  link: {
    marginLeft: '5px',
    color: '#007BFF',
    textDecoration: 'none',
    transition: 'color 0.3s',
  },
  linkHover: {
    color: '#0056b3',
  },
  footer: {
    color: '#777',
  },
};

export default ContactUs;
