import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Container, Typography, Card, CardContent, Button, IconButton, Box, Grid } from '@mui/material';
import Home from './Home';
import { CheckCircle, Cancel } from '@mui/icons-material';
import SimpleBottomNavigation from './SimpleBottomNavigation';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import logo from './logo.jpg'; 
import HomePage from './Header';
import Cookies from 'js-cookie'; 
import Header from './Header';
import api from './Interceptor';

function Index() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [levelData, setLevelData] = useState(null);
  const [starCount, setStarCount] = useState(0);
  const [userRole, setUserRole] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleString());
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleString());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchStarData = async () => {
        try {
          const response = await api.get(`/Levels/${userId}`);
          const data = response.data; // Access the response data correctly
  
          setLevelData(data);
          setStarCount(data.star); // Update star count from fetched data
        } catch (error) {
          setError(error.message); // Handle errors
        }
      };
  
      fetchStarData();
    }
  }, [userId]);
  const handleAddToWallet = (amount) => {
    updateWalletBalance(amount);
    alert(`${amount} added to your wallet!`);
  };

  const updateWalletBalance = (amount) => {
    // Logic to update the wallet balance
  };

  useEffect(() => {
    const fetchUserData = async (userId, token) => {
      try {
        const response = await api.get(`/UserRegistration/${userId}`, {
        
        });
        setUserData(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const token = sessionStorage.getItem('authToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const { sub: id, role } = decodedToken;

        setUserRole(role);
        setUserId(id);

        if (id) {
          fetchUserData(id, token);
        }
      } catch (error) {
        setError("Failed to decode token");
        setLoading(false);
      }
    } else {
      setError("No authentication token found");
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  const accountId = userData?.id || 'N/A';
  const accountOwner = userData?.name || 'N/A';
  const userStatus = userData?.isActive === true 
  ? 'Active' 
  : userData?.isActive === false 
  ? 'Inactive' 
  : 'Inactive';

  const wallet = userData?.walletAddress || 'N/A';
  // const userDatas = {
  //   isActive: true, // or false, or null to test different cases
  // };

  // const handleNavigateToRegister = () => {
  //   navigate(`/userRegister?referrerID=${userId}`);
  // };
  const handleCopyToClipboard = () => {
    const referralLink = `https://metaormoney.io/userRegister?referrerID=${userId}`;
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        alert('ID copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };
  return (
    <div>
 
      <Container sx={{ backgroundColor: '#f4f4f9', minHeight: '100vh', paddingY: 4,marginTop:'5%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4, p: 2, bgcolor: '#008080', borderRadius: 1 }}>
        <img 
  src={logo} 
  alt="Logo" 
  style={{ 
    width: 120, 
    height: 120, // Ensure width and height are equal for a circular shape
    borderRadius: '50%', // This makes the image round
    marginRight: 'auto',
    display: 'block' // Ensures it behaves like a block-level element
  }} 
/>

          <Box sx={{ textAlign: 'right', color: 'white' }}>
          <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: '2rem',  // Adjust font size
                color: 'white',  // Ensure text color is white
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',  // Increased shadow for better contrast
                borderBottom: '2px solid #ffffff',  // White border for emphasis
                paddingBottom: '4px',  // Add space below the text
                marginBottom: '0.5rem',  // Add space below the heading
                textTransform: 'Itallic',  // Uppercase for emphasis
              }}
            >
              MetaOrMoney.io
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'white',  // White text color for visibility
                fontSize: '1rem',  // Slightly smaller font size than the main heading
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.6)',  // Increased shadow for better readability
                fontWeight: '300',  // Lighter font weight to distinguish from the heading
                marginTop: '0.5rem',  // Add spacing above the subheading
                textAlign: 'right',  // Align text to the right, consistent with the design
              }}
            >
              {currentTime}
            </Typography>
          </Box>
        </Box>

        <Card sx={{ mb: 4, p: 2 }}>
          <CardContent>
          <Typography
  variant="h5"
  align="center"
  sx={{
    fontWeight: 'bold', // Make the text bold for emphasis
    fontSize: '2.25rem', // Increase font size for prominence
    color: '#207068', // Use a bright, contrasting color
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.5)', // Add shadow for depth and readability
    borderBottom: '3px solid #ffeb3b', // Add a border for emphasis
    paddingBottom: '0.5rem', // Space below the text
    marginBottom: '2rem', // Space below the heading
    letterSpacing: '0.1rem', // Improve letter spacing for readability
    textTransform: 'uppercase', // Convert text to uppercase for impact
  }}
>
  FAST TRACK INCOME
</Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 2, mb: 2, bgcolor: '#ffffff', boxShadow: 1, borderRadius: 1 }}>
                  <AccountCircleIcon sx={{ fontSize: 30, color: '#4caf50', mr: 2 }} />
                  <Typography>Account ID: <Typography component="span" sx={{ color: '#38a169' }}>{accountId}</Typography></Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 2, mb: 2, bgcolor: '#ffffff', boxShadow: 1, borderRadius: 1 }}>
                  <PersonIcon sx={{ fontSize: 30, color: '#2196f3', mr: 2 }} />
                  <Typography>Account Owner: {accountOwner}</Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 2, mb: 2, bgcolor: '#ffffff', boxShadow: 1, borderRadius: 1 }}>
                  <AccountBalanceWalletIcon sx={{ fontSize: 30, color: '#ffeb3b', mr: 2 }} />
                  <Typography>Wallet: <Typography component="span" sx={{ color: '#38a169' }}>{wallet}</Typography></Typography>
                </Card>
              </Grid>
            </Grid>
            <Card sx={{ p: 2, bgcolor: '#ffffff', boxShadow: 1, borderRadius: 1 }}>
      <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
        {starCount && starCount > 0 ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {Array.from({ length: starCount }, (_, index) => (
              <StarIcon key={index} sx={{ fontSize: 30, color: '#ffc107' }} />
            ))}
          </Box>
        ) : (
          <Typography sx={{ color: '#999' }}>Direct 10 Members to become a Star and Get opportunity to earn from monthly Global Pool</Typography>
        )}
      </CardContent>
    </Card>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleCopyToClipboard}>
          Refer Your ID Now
        </Button>
            </Box>
          </CardContent>
        </Card>
        <Card sx={{ mb: 4, p: 2, bgcolor: '#ffffff', boxShadow: 3, borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h6" align="center" gutterBottom>
          Career Pool
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {userStatus === 'Active' ? (
            <CheckCircle sx={{ fontSize: 40, color: 'green', mr: 2 }} />
          ) : (
            <Cancel sx={{ fontSize: 40, color: 'red', mr: 2 }} />
          )}
          <Typography variant="body2" color="text.secondary">
            Status: {userStatus}
        
            
          </Typography>
          
        </Box>
        

        <Typography variant="body2" color="text.secondary">
        {userStatus === 'Inactive' && (
  <Typography variant="h6" align="center" gutterBottom>
    Add USDT $50 BEP20 to wallet address:
    <Button 
      variant="contained" 
      color="primary"
      onClick={() => navigator.clipboard.writeText('0xB6aaA3909E934BAAcE9c7e26BAa51B9c769FF4fB')}
    >
      Copy Wallet Address
    </Button>
    <br />
    If already paid, then your account will be active within 10 Minutes
  </Typography>
)}
       
  <Typography variant="body2" color="text.secondary">
  </Typography>
</Typography>
      </CardContent>
    </Card>
      </Container>

     
    </div>
  );
}

export default Index;
