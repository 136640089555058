import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Ensure you have jwt-decode installed
import Cookies from 'js-cookie'; 
function PrivateRoute({ element, requiredRole }) {
  const location = useLocation();

  // Retrieve token from localStorage
  const token = sessionStorage.getItem('authToken');

  // Function to check if the token is valid and has the required role
  const isTokenValid = (token) => {
    if (!token) return false;
    
    try {
      const decodedToken = jwtDecode(token);
      const { exp, role } = decodedToken; // Decoding the token to get exp and role
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      
      // Check if the token is expired and if the role matches
      return exp > currentTime && role === requiredRole;
    } catch (error) {
      console.error('Invalid token:', error);
      return false;
    }
  };

  // Check if token is valid and has the required role
  const isAuthenticated = isTokenValid(token);

  // Redirect to login if not authenticated or role does not match
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Render the element if authenticated and role matches
  return element;
}

export default PrivateRoute;
