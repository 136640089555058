import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Typography, Box, Container, InputBase } from '@mui/material';
import axios from 'axios';
import SimpleBottomNavigation from './SimpleBottomNavigation';
import Header from './Header';
import { styled } from '@mui/material/styles';
import api from './Interceptor';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '& .MuiTablePagination-select': {
      marginRight: theme.spacing(2),
    },
    '& .MuiTablePagination-actions': {
      marginLeft: theme.spacing(2),
    },
  }));
  
const StyledButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '5px',
  fontSize: '14px',
  padding: '5px 10px',
  ...(variant === 'success' && {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  }),
  ...(variant === 'danger' && {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  }),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

function IsActive() {
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Number of items per page

    useEffect(() => {
        // Fetch data from the API endpoint
        api.get('/UserRegistration')
            .then(response => {
                setData(response.data); // Assuming the response contains an array of users
            })
            .catch(error => {
                console.error("There was an error fetching the data!", error);
            });
    }, []);

    const toggleActiveStatus = (id, currentUserData) => {
        const currentDate = new Date().toISOString();

        // Create the payload with all the required fields
        const payload = {
            id: currentUserData.id,
            name: currentUserData.name,
            address: currentUserData.address,
            email: currentUserData.email,
            password: currentUserData.password,
            confirmPassword: currentUserData.confirmPassword,
            mobile: currentUserData.mobile,
            walletAddress: currentUserData.walletAddress,
            referrerID: currentUserData.referrerID,
            signupDate: currentUserData.signupDate,
            role: currentUserData.role,
            activeDate: currentDate,
            isActive: !currentUserData.isActive, // Toggle the status
        };

        api.put(`/UserRegistration/${id}`, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log("Active status updated successfully!");

            // Update the local state to reflect the change
            setData(prevData =>
                prevData.map(user =>
                    user.id === id ? { ...user, isActive: !user.isActive } : user
                )
            );
        })
        .catch(error => {
            console.error("There was an error updating the active status!", error);
        });
    };

    // Filter data based on search input
    const filteredData = data.filter(item =>
        item.id.toString().includes(searchInput) ||
        item.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page
    };

    return (
      
                <Container sx={{ backgroundColor: '#f4f4f9', minHeight: '100vh', paddingY: 4,marginTop:'10%' }}>
                <Typography variant="h4" gutterBottom align="center" sx={{ color: "#0056b3", mb: 4 }}>
                    Active Users
                </Typography>

                <StyledInput
                    placeholder="Search by Id or Name"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>S. No.</StyledTableCell>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Address</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Mobile</StyledTableCell>
                                <StyledTableCell>Referrer ID</StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredData
        // Sort data so inactive items come first
        .sort((a, b) => a.isActive === b.isActive ? 0 : a.isActive ? 1 : -1)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => (
            <StyledTableRow key={item.id}>
                <TableCell>{(page * rowsPerPage) + index + 1}</TableCell>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.address}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.mobile}</TableCell>
                <TableCell>{item.referrerID}</TableCell>
                <TableCell>
                    <StyledButton
                        variant={item.isActive ? "info" : "success"}
                        size="small"
                        onClick={() => toggleActiveStatus(item.id, item)}
                        disabled={item.isActive}
                    >
                        {item.isActive ? "Already Active" : "Activate"}
                    </StyledButton>
                </TableCell>
            </StyledTableRow>
        ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <StyledPagination
                    rowsPerPageOptions={[ 20, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                
      
            </Container>

      
    );
}

export default IsActive;
