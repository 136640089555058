import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'; 
import './profile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDribbble, faTwitter, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import api from './Interceptor';
import { jwtDecode } from 'jwt-decode';

import SimpleBottomNavigation from './SimpleBottomNavigation';
import Header from './Header';
// Modal Component
function ChangePasswordModal({ isOpen, onClose }) {
    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handlePasswordChange = async () => {
        try {
            await api.post('/UserLogin/change-password', {
                email,
                currentPassword,
                newPassword,
            });
            alert('Password changed successfully!');
            onClose(); // Close the modal on success
        } catch (error) {
            alert('Failed to change password. Please try again.');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>&times;</button>
                <h2 className="modal-title">Change Password</h2>
                <div className="form-group">
                    <label>Email ID:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="input-field"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Old Password:</label>
                    <input
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="input-field"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>New Password:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="input-field"
                        required
                    />
                </div>
                <div className="modal-footer">
                    <button className="btn-blue" onClick={handlePasswordChange}>Change Password</button>
                </div>
            </div>
        </div>
    );
}

// Profile Component
const Profile = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    useEffect(() => {
        const fetchUserData = async (userId, token) => {
            try {
                const response = await api.get(`/UserRegistration/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserData(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const token = sessionStorage.getItem('authToken');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const { sub: id } = decodedToken;

                if (id) {
                    fetchUserData(id, token);
                }
            } catch (error) {
                setError("Failed to decode token");
                setLoading(false);
            }
        } else {
            setError("No authentication token found");
            setLoading(false);
        }
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
        <div className="card8">
            <br />
            <br />
            <br />
            <br />
            {/* Use RoboHash for generating random avatars */}
            <img 
                src={`https://robohash.org/${Math.random()}.png?set=set1`} 
                alt="User Avatar" 
                style={{ width: '100%' }} 
            />
            <h1>{userData?.name || 'John Doe'}</h1>
            <p>Address: {userData?.address || 'N/A'}</p>
            <p>Email: {userData?.email || 'N/A'}</p>
            <p>Mobile: {userData?.mobile || 'N/A'}</p>
            <p>Wallet Address: {userData?.walletAddress || 'N/A'}</p>
            <p>Referrer ID: {userData?.referrerID || 'N/A'}</p>
    
            <button className="btn-open-modal" onClick={() => setIsModalOpen(true)}>Change Password</button>
    
            <ChangePasswordModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
    </div>
    

    );
};

export default Profile;
