import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { Container, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Alert, TablePagination, InputBase, styled, Box } from '@mui/material';
import SimpleBottomNavigation from './SimpleBottomNavigation';
import api from './Interceptor';
import Cookies from 'js-cookie';
// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '5px',
  fontSize: '16px',
  padding: '5px 10px',
  ...(variant === 'success' && {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  }),
  ...(variant === 'danger' && {
    backgroundColor: '#0056b3',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#003d7a',
    },
  }),
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

function Team() {
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchTeamData(selectedLevel);
  }, [selectedLevel]);

  const fetchTeamData = async (level) => {
    setLoading(true);
    setError(null);

    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error("No authentication token found");
      }

      const decodedToken = jwtDecode(token);
      const userId = decodedToken.sub;

      const response = await api.get(`/Levels/level${level}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTeamData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLevelClick = (level) => {
    setSelectedLevel(level);
  };

  const filteredData = teamData.filter(item =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container
      sx={{
        backgroundColor: '#f4f4f9',
        minHeight: '100vh',
        paddingY: 4,
        marginTop: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
        <Typography
          variant="h4"
          sx={{ color: "#0056b3", mb: { xs: 2, sm: 0 }, textAlign: { xs: 'center', sm: 'left' } }}
        >
          Team Levels
        </Typography>

      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'flex-start' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          width: '100%',
          mb: 4,
        }}
      >
      
        <StyledInput
          placeholder="Search by Name"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          sx={{
            marginBottom: { xs: 2, sm: 0 },
            marginRight: { sm: 2 },
            width: { xs: '100%', sm: '300px' },
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 1,
            justifyContent: { xs: 'center', sm: 'flex-end' },
            width: '100%',
          }}
        >
          {[1, 2, 3, 4, 5].map(level => (
            <StyledButton
              key={level}
              variant={selectedLevel === level ? 'success' : 'danger'}
              onClick={() => handleLevelClick(level)}
            >
              Level {level}
            </StyledButton>
          ))}
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Wallet Address</StyledTableCell>
          
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(member => (
              <StyledTableRow key={member.id}>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.email}</TableCell>
                <TableCell>{member.walletAddress}</TableCell>
           
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <StyledPagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 4,
          width: '100%',
        }}
      >
     
      </Box>
    </Container>
  );
}

export default Team;
